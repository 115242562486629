import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Layout from '../layouts/Default';
import Section from '../components/Section';
import RouteNotFoundError from '../img/404-error-image.svg';

const browser = typeof window !== 'undefined' && window;

const RouteNotFoundErrorImage = styled('img')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'block',
    maxHeight: '200px',
  },
  display: 'block',
  maxHeight: '300px',
  margin: 'auto',
}));

const NotFoundPage = () => (
  browser && (
    <Layout>
      <Section
        sx={(theme) => ({
          paddingTop: theme.spacing(6),
          paddingBottom: theme.spacing(6),
        })}
      >
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        <Grid item>
          <RouteNotFoundErrorImage src={RouteNotFoundError} alt="404 Error" />
        </Grid>
      </Section>
    </Layout>
  )
);

NotFoundPage.propTypes = {
};

export default NotFoundPage;
